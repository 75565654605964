<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-5">
                <span>Name</span>
                <b-form-input v-model="form.name"/>
            </div>
            <div class="col-12 col-sm-2">
                <span>Type</span>
                <div class="mt-1">
                    <b-button-group>
                        <b-button @click="form.type = 'Buy'" :variant="form.type == 'Buy' ? 'primary' : 'outline-primary'" >Buy</b-button>
                        <b-button @click="form.type = 'Sell'" :variant="form.type == 'Sell' ? 'primary' : 'outline-primary'" style="margin-left:10px" >Sell</b-button>
                    </b-button-group>
                </div>
            </div>
            <!-- <div class="col-12 col-sm-4 text-right">
                <span>Type</span>
                <div class="mt-1">
                    <b-button-group>
                        <b-button @click="form.is_free = 0" :variant="form.is_free == 0 ? 'primary' : 'outline-primary'" style="margin-right:10px" >Not is free</b-button>
                        <b-button @click="form.is_free = 1" :variant="form.is_free == 1 ? 'primary' : 'outline-primary'" >Is free</b-button>
                    </b-button-group>
                </div>
            </div> -->
            <div class="col-12 pt-4">
                <span>Upload image</span>
                <input
                type="file"
                class="form-control rounded-xs"
                ref="image"
                placeholder="Your Password"
                @change="changeFiles"
                />
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <span>Precio entrada</span>
                <b-form-input v-model="form.win"/>
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <span>Stop Loss</span>
                <b-form-input v-model="form.loss"/>
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <span>Take Profit</span>
                <b-form-input v-model="form.total"/>
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <span>Total Profit</span>
                <b-form-input v-model="form.total_profit"/>
            </div>
            <div class="text-right mt-3">
                <b-button variant="secondary" class="mr-3">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Uploading signal" title="Create signal" @click="onSubmit"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data () {
        return {
            form: {
                name: null,
                image: 'asf',
                type: null,
                win: null,
                loss: null,
                total: null,
                total_profit: null,
                is_free: 1
            },
            loading: false
        }
    },
    methods: {
        ...mapActions('signal', ['storeSignal']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit () {
            this.loading = true
                const formData = new FormData()
                formData.append('name', this.form.name)
                formData.append('file', this.form.image)
                formData.append('type', this.form.type)
                formData.append('win', this.form.win)
                formData.append('loss', this.form.loss)
                formData.append('total', this.form.total)
                formData.append('total_profit', this.form.total_profit)
                formData.append('is_free', this.form.is_free)
            
            this.storeSignal(formData).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        }
    }
}
</script>